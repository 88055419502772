utils.ready(function() {

  /**
   * Make submenus work on touch devices.
   */
  // var mainNavElements = document.querySelectorAll('.main-navigation-item.has-dropdown');

  // for (var i = mainNavElements.length - 1; i >= 0; i--) {
  //   console.log('touched menu item: ' + mainNavElements[i]);

  //   mainNavElements[i].on('touchstart', function(event){

  //     if(mainNavElements[i].hasClass('-is-open')) {
  //       return true;
  //     } else {
  //       mainNavElements[i].addClass('-is-open');
  //       event.preventDefault();
  //     }

  //     return false;
  //   });
  // }


  /**
   * Responsive Navigation
   */
  var openNavElement     = document.querySelector('.-do-open-nav'),
      closeNavElement    = document.querySelector('.-do-close-nav'),
      navElement         = document.querySelector('.site-nav'),

      // openSearchElement  = document.querySelector('.-do-open-search'),
      // closeSearchElement = document.querySelector('.-do-close-search'),
      // searchElement      = document.querySelector('.search'),
      // searchInput        = document.querySelector('.search input'),

      docClass           = document.documentElement.classList,
      _navIsOpen         = false,
      _searchIsOpen      = false;

  // Abort if toggle element is not present
  if(!openNavElement) return;

  function toggleNav(force) {
    var isOpen;

    if(typeof force === 'boolean') {
      if(_navIsOpen !== force) {
        isOpen = docClass.toggle('has-open-nav', force);
      }
    } else {
      isOpen = docClass.toggle('has-open-nav');
    }

    if(isOpen) {
      // toggleSearch(false);
      window.scroll(0, 0);
    }

    _navIsOpen = isOpen;
  }


  // function toggleSearch(force) {
  //   var isOpen;

  //   if(typeof force === 'boolean') {
  //     if(_searchIsOpen !== force) {
  //       isOpen = docClass.toggle('has-open-search', force);
  //     }
  //   } else {
  //     isOpen = docClass.toggle('has-open-search');
  //   }

  //   if(isOpen) {
  //     window.scroll(0, 0);
  //     searchInput.focus();
  //   } else {
  //     searchInput.blur();
  //   }

  //   _searchIsOpen = isOpen;
  // }


  openNavElement.addEventListener('click', function(e) {
    e.preventDefault();
    toggleNav(true);
  });

  closeNavElement.addEventListener('click', function(e) {
    e.preventDefault();
    toggleNav(false);
  });


  // openSearchElement.addEventListener('click', function(e) {
  //   e.preventDefault();
  //   toggleSearch(true);
  // });

  // closeSearchElement.addEventListener('click', function(e) {
  //   e.preventDefault();
  //   toggleSearch(false);
  // });

});