/**
 * addClass
 *
 * @param  String Class name
 * @return Element
 * @since  1.0
 */
Node.prototype.addClass = function(className){
  if(this.classList){
    this.classList.add(className);
  } else {
    this.className += ' ' + className;
  }

  return this;
};

/**
 * removeClass
 *
 * @param  String Class name
 * @return Element
 * @since  1.0
 */
Node.prototype.removeClass = function(className){
  if(this.length == 1){
    if (this.classList){
      this.classList.remove(className);
    } else {
      this.className = this.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  return this;
};

/**
 * hasClass
 *
 * @param  String Class name
 * @return Boolean
 * @since  1.0
 */
Node.prototype.hasClass = function(className){
  if (this.classList){
    return this.classList.contains(className);
  } else {
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(this.className);
  }
};