(function() {

  const AccordeonItem = id => {
    const headline = document.getElementById(id);
    const el = headline.parentNode;
    const btn = headline.querySelector("button");
    const content = el.querySelector(".accordeon-content");
    const contentInner = content.querySelector("div");
    
    const setState = (id) => {
      const hasState = !!id;
      
      el.setAttribute("data-open", hasState);
      btn.setAttribute("aria-expanded", hasState);
      
      content.hidden = !hasState;
      
      history.replaceState(null, null, hasState ? '#' + id : window.location.pathname + window.location.search);
    }
    
    const open = () => {
      setState(headline.id);
      content.style.height = contentInner.clientHeight + 'px';
    };
    
    const close = () => {
      setState(null);
      content.style.height = 0;
    };
    
    const toggle = () => {
      let isOpen = el.getAttribute("data-open") === 'true' || false;
      
      if (isOpen) {
        close();  
      } else {
        open();
      }
    };

    btn.onclick = toggle;
    
    if (window.location.hash.substr(1) === headline.id) {
      open();
      btn.focus();
    }
  };
  
  
  const Accordeon = (el) => {
    const items = [];
    const children = el.querySelectorAll('.accordeon-item');
    
    children.forEach(item => {
      items.push(
        new AccordeonItem( item.querySelector('h4').id )
      );
    });
    
    console.log(el);
  };
  
  
  const elements = document.querySelectorAll('[data-accordeon]');
  elements.forEach(acc => {
    new Accordeon(acc);
  });
})();