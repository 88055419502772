import "lazysizes";

var Site = window.Site || {};

Site.utils = {
  ready: function(fn) {
    if (document.readyState != "loading") {
      fn();
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  }
};

window.utils = Site.utils;

require("./helpers.js");

require("./header.js");
require("./accordeon.js");
require("./photoswipe.js");

require("./templates/team.js");
