const MIN_GROW_FACTOR = 0.6;
const MAX_GROW_FACTOR = 1;
const CHG_GROW_FACTOR = 5;

const MIN_POS_FACTOR = 0.25;
const MAX_POS_FACTOR = 1.25;

const Team = {
  $el: null,
  $members: null,

  activeFilter: false,

  init() {
    this.$el = document.getElementById("team");

    (this.$members = this.$el.querySelectorAll(".member")),
      this.$members.forEach(member => {
        const growOrShrink = getRandomBoolean();
        const growFactor = CHG_GROW_FACTOR;

        const widthFrom =
          getRandomBetween(MIN_GROW_FACTOR, MAX_GROW_FACTOR) * 100;

        const widthTo = growOrShrink
          ? widthFrom + growFactor
          : widthFrom - growFactor;

        const verticalFactor = getRandomBetween(MIN_POS_FACTOR, MAX_POS_FACTOR);
        const horizontalFactor = getRandomBetween(
          MIN_POS_FACTOR,
          MAX_POS_FACTOR
        );
        const leftOrRight = getRandomBoolean();
        const topOrBottom = getRandomBoolean();

        const verticalPosition = topOrBottom
          ? verticalFactor
          : verticalFactor * -1;

        const horizontalPosition = leftOrRight
          ? horizontalFactor
          : horizontalFactor * -1;

        const avatar = member.querySelector(".avatar");

        if (avatar) {
          avatar.style.setProperty("width", `${widthFrom}%`);

          member.style.setProperty("top", `${verticalPosition}em`);
          member.style.setProperty("left", `${horizontalPosition}em`);
        }
      });

    this.$members = shuffle(this.$members);
  },

  initButtons() {
    document.querySelectorAll(".team-toggle-filter").forEach(button => {
      button.addEventListener("click", event => {
        const btn = event.target;
        const type = btn.getAttribute("data-type");

        this.toggleFilter(type);
      });
    });

    document.querySelectorAll(".team-resort").forEach(button => {
      button.addEventListener("click", () => {
        this.init();
      });
    });

    // this.$members.forEach(member => {
    //   member.addEventListener('click', () => {
    //     this.toggleMember(member);
    //   });
    // });
  },

  releaseFilter() {
    this.$members.forEach(member => {
      member.classList.remove("-hidden");
    });

    document.querySelectorAll(".team-toggle-filter").forEach(btn => {
      btn.classList.remove("btn-primary");
      btn.classList.add("btn-tertiary");
    });

    this.activeFilter = false;
  },

  addFilter(type) {
    this.releaseFilter();

    document
      .querySelector(`.team-toggle-filter[data-type="${type}"]`)
      .classList.remove("btn-tertiary");
    document
      .querySelector(`.team-toggle-filter[data-type="${type}"]`)
      .classList.add("btn-primary");

    const membersWithoutType = Team.$el.querySelectorAll(
      `.member:not([data-type="${type}"])`
    );

    membersWithoutType.forEach(member => {
      member.classList.add("-hidden");
    });

    this.activeFilter = type;
  },

  toggleFilter(type) {
    if (this.activeFilter === type) {
      this.releaseFilter();
    } else {
      this.addFilter(type);
    }
  },

  toggleMember(member) {
    if (member.classList.contains("-zoomed")) {
      this.$members.forEach(otherMember => {
        otherMember.classList.remove("-zoomed");
      });
    } else {
      this.$members.forEach(otherMember => {
        otherMember.classList.remove("-zoomed");
      });

      member.classList.add("-zoomed");
    }
  }
};

utils.ready(function() {
  if (document.getElementById("team")) {
    Team.init();
    Team.initButtons();
  }
});

function getRandomBetween(min, max) {
  return Math.random() * (max - min) + min;
}

function getRandomBoolean() {
  return Math.random() < 0.5;
}

function shuffle(elems) {
  allElems = (function() {
    var ret = [],
      l = elems.length;
    while (l--) {
      ret[ret.length] = elems[l];
    }
    return ret;
  })();

  var shuffled = (function() {
      var l = allElems.length,
        ret = [];
      while (l--) {
        var random = Math.floor(Math.random() * allElems.length),
          randEl = allElems[random].cloneNode(true);
        allElems.splice(random, 1);
        ret[ret.length] = randEl;
      }
      return ret;
    })(),
    l = elems.length;

  while (l--) {
    elems[l].parentNode.insertBefore(shuffled[l], elems[l].nextSibling);
    elems[l].parentNode.removeChild(elems[l]);
  }

  return shuffled;
}
